import "./App.css";
import logo from "./dummy.png";

function App() {
  return (
    <>
      <div className="App">
        <header className="App-header">
          <div className="App-Section">
            <div style={{ background: "white" }}>
              <img src={logo} className="App-logo" alt="logo" />
            </div>

            <div className="Text-Left">
              As the named suggested: DummiesCoin.
              <br />
              This coin is useless at this moment. I am learning blockchain and
              smart contract, so this is my playground to play around the
              technology. I don't have any predefined target or road map of the
              coin usage. Any suggestion is welcomed.
              <br />
              <br />
              One of the use case I can think of: People can join an article
              program, so that people write the article for dummies can receive
              donation from the viewer.
              <br />
              <br />
              You can buy the coin at PancakeSwap at{" "}
              <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xfA77eE33393184D848F133dB3bFE0CfE0d047879">
                here
              </a>
            </div>
          </div>

          <div className="App-Section Text-Left" style={{ marginTop: "10px" }}>
            RoadMap:
            <ol>
              <li>Add farming contract</li>
              <li>Add use case smart contract</li>
            </ol>
          </div>

          <div style={{ marginTop: "10px", color: "white" }}>
            <a
              href="https://twitter.com/DummiesCoin"
              target="_blank"
              rel="noreferrer"
            >
              <span
                role="img"
                aria-label="twitter"
                className="anticon anticon-twitter"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="twitter"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M928 254.3c-30.6 13.2-63.9 22.7-98.2 26.4a170.1 170.1 0 0075-94 336.64 336.64 0 01-108.2 41.2A170.1 170.1 0 00672 174c-94.5 0-170.5 76.6-170.5 170.6 0 13.2 1.6 26.4 4.2 39.1-141.5-7.4-267.7-75-351.6-178.5a169.32 169.32 0 00-23.2 86.1c0 59.2 30.1 111.4 76 142.1a172 172 0 01-77.1-21.7v2.1c0 82.9 58.6 151.6 136.7 167.4a180.6 180.6 0 01-44.9 5.8c-11.1 0-21.6-1.1-32.2-2.6C211 652 273.9 701.1 348.8 702.7c-58.6 45.9-132 72.9-211.7 72.9-14.3 0-27.5-.5-41.2-2.1C171.5 822 261.2 850 357.8 850 671.4 850 843 590.2 843 364.7c0-7.4 0-14.8-.5-22.2 33.2-24.3 62.3-54.4 85.5-88.2z"></path>
                </svg>
              </span>
              <span className="c-contactChannel__title">Twitter</span>
            </a>
          </div>
        </header>
      </div>
    </>
  );
}

export default App;
